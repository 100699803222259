import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { replaceUnicode } from "../../components/utils/utils"
import ReactMarkdown from "react-markdown/with-html"

import "../../graphql/fragments"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const ThankyouPage = ({ location }) => {
  const staticData = useStaticQuery(graphql`
    query {
      wordPress {
        page(id: "thankyou", idType: URI) {
          title
          featuredImage {
            mediaItemUrl
          }
          blocks {
            name
            ... on WordPress_CoreHeadingBlock {
              attributes {
                content
              }
            }
            ... on WordPress_CoreCoverBlock {
              attributes {
                url
                className
              }
            }
            ... on WordPress_CoreParagraphBlock {
              attributes {
                ... on WordPress_CoreParagraphBlockAttributesV3 {
                  content
                }
              }
            }
            ... on WordPress_AcfAcfgalleryBlock {
              name
              acf {
                carousel_type
                images {
                  title
                  mediaDetails {
                    file
                    height
                    width
                  }
                  slug
                  sourceUrl
                  acfAttachment {
                    location
                    expired
                  }
                  caption(format: RAW)
                  description(format: RAW)
                }
              }
            }
            ... on WordPress_CoreEmbedBlock {
              attributes {
                url
              }
            }
          }
          translation(language: ID) {
            title
            blocks {
              name
              ... on WordPress_CoreHeadingBlock {
                attributes {
                  content
                }
              }
              ... on WordPress_CoreCoverBlock {
                attributes {
                  url
                  className
                }
              }
              ... on WordPress_CoreParagraphBlock {
                attributes {
                  ... on WordPress_CoreParagraphBlockAttributesV3 {
                    content
                  }
                }
              }
              ... on WordPress_AcfAcfgalleryBlock {
                name
                acf {
                  carousel_type
                  images {
                    title
                    mediaDetails {
                      file
                      height
                      width
                    }
                    slug
                    sourceUrl
                    acfAttachment {
                      location
                      expired
                    }
                    caption(format: RAW)
                    description(format: RAW)
                  }
                }
              }
              ... on WordPress_CoreEmbedBlock {
                attributes {
                  url
                }
              }
            }
          }
          seo {
            title
            twitterTitle
            twitterDescription
            metaDesc
            opengraphTitle
            opengraphDescription
          }
        }
      }
    }
  `)

  const ogUrl = process.env.BASE_URL + location.pathname + "/"
  const wordPress = staticData.wordPress.page
  const seo = staticData.wordPress.page?.seo

  let coverItems = []
  wordPress && wordPress?.blocks?.map(item => {
    if(item.name === "core/cover"){
      coverItems.push({url: item.attributes.url, className: item.attributes.className})
    }
  })

  useEffect(() => {
    wordPress && wordPress?.blocks?.map(item => {
      if(item.name === "core/cover"){
        coverItems.push({url: item.attributes.url, className: item.attributes.className})
      }
    })

    console.log('useEffect purchaseDataLayer', sessionStorage.getItem("purchaseDataLayer"))
    if (sessionStorage.getItem("purchaseDataLayer") !== null) {
      const dataLayer = JSON.parse(sessionStorage.getItem("purchaseDataLayer"))
      dataLayer.page_title = "Thank You"
      dataLayer.page_url = window.location.href
      window.dataLayer = window.dataLayer || []
      // Disable facebook purchase pixel
      // window.dataLayer.push(dataLayer)
      console.log('useEffect dataLayer', dataLayer)
      sessionStorage.removeItem("purchaseDataLayer")
    }
  }, [])

  const featuredImage = staticData?.wordPress?.page?.featuredImage?.mediaItemUrl

  return (
    <Layout location={location} currentLocation={location.pathname} lang="EN">
      <SEO
        title={seo?.title}
        fbTitle={seo?.opengraphTitle || seo?.title}
        twitterTitle={seo?.twitterTitle || seo?.title}
        url={ogUrl}
        fbDescription={seo?.opengraphDescription}
        description={seo?.opengraphDescription}
        twitterImg={featuredImage}
        twitterDescription={seo?.twitterDescription || seo?.opengraphDescription}
        img={featuredImage}
        fbImg={featuredImage}
      />
      <Helmet>
        <body className="bd-page" />
      </Helmet>
      <section
          className={`cover cover-main cover-img cover-full-top cover-md  ${coverItems[0]?.className}`}
          alt={wordPress?.title}
          style={{
            background: `url(${coverItems[0].url}) no-repeat center`,
          }}
        >
          <div className={`container text-white text-center flex-column align-items-center justify-content-center`}>
            {wordPress?.blocks.map((item, i) => {
              switch (item.name) {
                case "core/heading":
                  return (
                    <div key={`heading-${i}`}>
                      <h2 className="h1">{replaceUnicode(item.attributes.content)}</h2>
                    </div>
                  )
                case "core/paragraph":
                  return (
                    <div key={`paragraph-${i}`} className="font-size-lg">
                      <ReactMarkdown source={item.attributes.content} escapeHtml={false} />
                    </div>
                  )
                default:
                  return <></>
              }
            })}
          </div>
          <div className="bg-overlay bg-overlay-70"></div>
        </section>
    </Layout>
  )
}

export default ThankyouPage
